import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleAuth from "@/store/auth/moduleAuth";
import moduleBusinessTypes from "@/store/nextcart/businessTypes/moduleBusinessTypes";
import moduleCountry from "@/store/nextcart/country/moduleCountry";
import moduleState from "@/store/nextcart/state/moduleState";
import moduleArea from "@/store/nextcart/area/moduleArea";

import moduleRole from "@/store/cloudX/roles/moduleRole";
import moduleUserManagement from "@/store/cloudX/user-management/moduleUserManagement";

import moduleBusiness from "@/store/nextcart/business/moduleBusiness";
import moduleImage from "@/store/cloudX/imageUploader/moduleImage";
import moduleProductCategory from "@/store/cloudX/productCategory/moduleProductCategory";
import moduleOrder from "@/store/order/moduleOrder";
import moduleShippingMethod from "@/store/cloudX/shippingMethod/moduleShippingMethod";
import modulePaymentMethod from "@/store/cloudX/paymentMethods/modulePaymentMethod";
import moduleSliderImage from "@/store/cloudX/adsManage/moduleSliderImage";
import moduleDodManage from "@/store/cloudX/dodManage/moduleDodManage";
import moduleDodSetting from "@/store/cloudX/dodManage/moduleDodSetting";
import moduleProduct from "@/store/cloudX/product/moduleProduct";
import moduleSiteSetting from "@/store/cloudX/siteSetting/moduleSiteSetting";
import moduleUserManage from "@/store/cloudX/userManagement/moduleUserManage"
import moduleAdsManage from "@/store/cloudX/adsManage/moduleAdsManage";
import moduleCustomer from "@/store/cloudX/customer/moduleCustomer";
import moduleFaqCategories from "@/store/cloudX/faq/moduleFaqCategories";
import moduleFaq from "@/store/cloudX/faq/moduleFaq";
import moduleInvoice from "@/store/cloudX/invoice/moduleInvoice";
import moduleShopFeatures from "@/store/cloudX/shopFeatures/moduleShopFeatures";
import moduleTerms from "@/store/cloudX/terms/moduleTerms";
import moduleAboutUs from "@/store/cloudX/aboutUs/moduleAboutUs";
import moduleForgotPassword from "@/store/cloudX/forgotPassword/moduleForgotPassword";
import moduleGatewayInfo from "@/store/cloudX/gateway/moduleGatewayInfo";
import moduleSellerShipping from "@/store/cloudX/sellerShippingMethod/moduleSellerShipping";
import moduleSellerOrder from "@/store/order/moduleSellerOrder";
import moduleWithdrawal from "@/store/cloudX/withdraw/moduleWithdrawal";
import moduleSellerFinance from "@/store/cloudX/sellerFinance/moduleSellerFinance";
import moduleShippingWithdraw from "@/store/cloudX/shippingWithdraw/moduleShippingWithdraw";
import moduleShippingFinance from "@/store/cloudX/shippingFinance/moduleShippingFinance";
import moduleReportGenerator from "@/store/cloudX/reportGenerator/moduleReportGenerator";

import moduleThemeTypes from "@/store/nextcart/themeTypes/moduleThemeTypes";
import moduleTheme from "@/store/nextcart/theme/moduleTheme";
import moduleDeployment from "@/store/nextcart/deploymentUrl/moduleDeployment";
import moduleServerList from "@/store/nextcart/serverlist/moduleServerList";
import moduleOnboard from "@/store/nextcart/business/moduleOnboard";
import moduleLimit from "@/store/nextcart/limit/moduleLimit";
import modulePermissionGroup from "@/store/nextcart/permissionGroup/modulePermissionGroup";
import modulePermissionList from "@/store/nextcart/permission/modulePermissionList";
import moduleFeatureList from "@/store/nextcart/feature/moduleFeatureList";
import modulePlan from "@/store/nextcart/plan/modulePlan";
import modulePricing from "@/store/nextcart/pricing/modulePricing";
import modulePermissionListing from "@/store/nextcart/permissionListing/modulePermissionListing";
import moduleProductSource from "@/store/nextcart/productSource/moduleProductSource";
import moduleProductSourceDistributor from "@/store/nextcart/productSource/moduleProductSourceDistributor";
import affiliateOnboard from "@/store/nextcart/affiliate/affiliateOnboard";
import moduleCoupon from "@/store/nextcart/coupon/moduleCoupon";
import moduleReservedSlugs from "@/store/nextcart/reservedSlug/moduleReservedSlugs";
import moduleAffiliateFinance from "@/store/nextcart/affiliate/moduleAffiliateFinance";
import moduleKnowledgeBase from "@/store/nextcart/knowledgeBase/moduleKnowledgeBase";
import moduleKnowledgeBaseCategory from "@/store/nextcart/knowledgeBase/moduleKnowledgeBaseCategory";
import moduleAffiliateWithdrawal from "@/store/nextcart/affiliate/moduleAffiliateWithdrawal";
import moduleFxRate from "@/store/nextcart/fxRate/moduleFxRate";
import moduleFeatureManagement from "@/store/nextcart/featureManagement/moduleFeatureManagement";
import moduleVersionData from "@/store/nextcart/verisonData/moduleVersionData";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    'business':moduleBusiness,
    'auth': moduleAuth,
    'businessType':moduleBusinessTypes,
    'country':moduleCountry,
    'state': moduleState,
    'area': moduleArea,
    'reservedSlug': moduleReservedSlugs,
    'themeType': moduleThemeTypes,
    'theme':moduleTheme,
    'deployment':moduleDeployment,
    'serverlist':moduleServerList,
    'onboard':moduleOnboard,
    'dayLimit':moduleLimit,
    'permissionGroup':modulePermissionGroup,
    'permissionList':modulePermissionList,
    'permissionListing':modulePermissionListing,
    'productSource':moduleProductSource,
    'productSourceDistributor':moduleProductSourceDistributor,
    'featureList':moduleFeatureList,
    'plan':modulePlan,
    'pricing':modulePricing,
    'affiliate':affiliateOnboard,
    'affiliateFinance':moduleAffiliateFinance,
    "coupon":moduleCoupon,
    "knowledgeBase":moduleKnowledgeBase,
    "knowledgeBaseCategory":moduleKnowledgeBaseCategory,
    "fxRateStore":moduleFxRate,
    "versionData":moduleVersionData,
    'affiliateFinanceWithdrawal':moduleAffiliateWithdrawal,
    'featureManagement':moduleFeatureManagement,

    'role': moduleRole,
    'user': moduleUserManagement,
    'image':moduleImage,
    'productCategory':moduleProductCategory,
    'sliderImage':moduleSliderImage,
    "order":moduleOrder,
    "sellerOrder":moduleSellerOrder,
    "shippingMethod":moduleShippingMethod,
    "paymentMethod":modulePaymentMethod,

    "dodProducts":moduleDodManage,
    "dodSettings":moduleDodSetting,
    "products":moduleProduct,
    'siteSettings':moduleSiteSetting,
    'legal':moduleTerms,
    "userManagement":moduleUserManage,
    "customer":moduleCustomer,
    'adsManage':moduleAdsManage,
    'faq_Categories':moduleFaqCategories,
    'faq':moduleFaq,
    'invoice': moduleInvoice,
    'shop_features':moduleShopFeatures,
    'about_us':moduleAboutUs,
    'forgotPassword':moduleForgotPassword,
    'gateway':moduleGatewayInfo,
    'sellerShipping':moduleSellerShipping,
    'withdraw':moduleWithdrawal,
    'sellerFinance':moduleSellerFinance,
    'sellerShippingFinance':moduleShippingFinance,
    'shippingWithdraw':moduleShippingWithdraw,
    'reportGenerator':moduleReportGenerator,



  },
  strict: process.env.DEV,
})
