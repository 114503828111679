export default [

    {
        path: '/dashboard/feature-management',
        name: 'feature-manage-list',
        component: () => import('@/views/pages/nextcart/FeatureManagement/featureList.vue'),
        meta:{
            codename:'view_featuremanagelist'
        }

    },

]
