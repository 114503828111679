export default [
   {

        path: '/dashboard/version-data/settings',
        name: 'version-data-settings',
        component: () => import('@/views/pages/nextcart/version-data/versionDataModule.vue'),
        meta:{
            codename:'view_versionSettings'
        }

    },


]
