import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import store from '@/store/index'
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/cloudX/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
// import addBusiness from './routes/cloudX/add-business'
// import createUser from './routes/cloudX/create-user'
// import database from './routes/cloudX/database'
// import legalities from './routes/cloudX/legalities'
// import xPages from './routes/cloudX/pages'
// import {can} from "@core/libs/acl/utils";
// import product from "@/router/routes/cloudX/product";
// import order from "@/router/routes/cloudX/order";
// import method from "@/router/routes/cloudX/method";
// import adsManage from "@/router/routes/cloudX/adsManage";
// import dodManage from "@/router/routes/cloudX/dodManage";
// import siteSetting from "@/router/routes/cloudX/siteSetting";
// import customer from "@/router/routes/cloudX/customer";
// import faq from "@/router/routes/cloudX/faq";
// import aboutUs from "@/router/routes/cloudX/aboutUs";
// import forgotPassword from "@/router/routes/cloudX/forgotPassword";
// import finance from "@/router/routes/cloudX/finance";
// import gateway from "@/router/routes/cloudX/gateway";
// import shippingWithdrawal from "@/router/routes/cloudX/shippingWithdrawal";

import database from './routes/nextcart/database'
import theme from "@/router/routes/nextcart/theme";
import server from "@/router/routes/nextcart/server";
import business from "@/router/routes/nextcart/business";
import daylimit from "@/router/routes/nextcart/daylimit";
import permissionGroup from "@/router/routes/nextcart/permission";
import feature from "@/router/routes/nextcart/feature";
import plan from "@/router/routes/nextcart/plan";
import pricing from "@/router/routes/nextcart/pricing";
import permissionListing from "@/router/routes/nextcart/permissionListing";
import productSource from "@/router/routes/nextcart/productSource";
import affiliate from "@/router/routes/nextcart/affiliate";
import coupon from "@/router/routes/nextcart/coupon";
import knowledgeBase from "@/router/routes/nextcart/knowledgeBase";
import fxrate from "@/router/routes/nextcart/fxrate";
import feature_list from "@/router/routes/nextcart/feature_list";
import versionData from "@/router/routes/nextcart/versionData";


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: {name: 'dashboard'}},
        ...apps,
        ...dashboard,
        ...pages,
        ...chartsMaps,
        ...formsTable,
        ...uiElements,
        ...others,
        ...knowledgeBase,
        ...fxrate,
        ...versionData,



        // ...addBusiness,
        ...database,
        ...theme,
        ...server,
        ...business,
        ...daylimit,
        ...permissionGroup,
        ...feature_list,
        ...feature,
        ...permissionListing,
        ...productSource,
        ...plan,
        ...pricing,
        ...affiliate,
        ...coupon,
        // ...legalities,
        // ...createUser,
        // ...xPages,
        // ...product,
        // ...order,
        // ...method,
        // ...adsManage,
        // ...dodManage,
        // ...siteSetting,
        // ...customer,
        // ...faq,
        // ...aboutUs,
        // ...forgotPassword,
        // ...shippingWithdrawal,
        // ...finance,
        // ...gateway,

        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {

    (to.name !== 'auth-login' && to.name!=='forgot-password'&& to.name!=='reset-password')? store.dispatch('auth/isLoggedIn') : ''

    const isLoggedIn = store.state.auth.isUserLoggedIn()

    let permissions = JSON.parse(localStorage.getItem('permissions'))
    //cango updated
    let canGo = true;
    // if (to.meta.codename != undefined) {
    //     canGo = permissions.includes(to.meta.codename)
    // } else {
    //     canGo = true
    // }

    if (to.name === 'auth-login'||to.name === 'forgot-password' || to.name === 'reset-password') {
        if (isLoggedIn) {
            return next({name: 'dashboard'})
        } else {
            next()
        }

    }else {
        if (isLoggedIn) {
            if (canGo) {

                return next()
            } else {
                return next({name: 'misc-not-authorized'})
            }
        } else {
            return next({name: 'auth-login'})
        }
    }


    // if(!(to.name=='auth-login')){
    //   if(isLoggedIn){
    //     return next()
    //   }else{
    //     return next({ name: 'auth-login' })
    //   }
    // }else{
    //   return next()
    // }
    // if (!canNavigate(to)) {
    //   // Redirect to login if not logged in
    //   if (!isLoggedIn)
    //
    //   // If logged in => not authorized
    //   return next({ name: 'misc-not-authorized' })
    // }

    // Redirect if logged in
    // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    //   const userData = getUserData()
    //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // }


})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
