import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    featureUpdate : 'FEATURE_UPDATE',
    featureDelete : 'FEATURE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        feature: [],
        featureLoading: false,
        featureError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_FEATURE (state, feature) {
            state.feature = feature;
        },

        FEATURE_LOADING (state, loading) {
            state.featureLoading = loading;
        },

        FEATURE_ERROR (state, hasError) {
            state.featureError = hasError;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateFeature({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.featureUpdate,
                async () => {
                    let payloadData={
                        'module_name':payload.module_name,
                        'serial':payload.serial,
                        'comment':payload.comment
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.feature_manage_edit+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchFeature');

                }
            )
            return response
        },

        async toggleFeatureShowStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.featureUpdate,
                async () => {
                    response= await axios.get(V1API.feature_manage_show_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchFeature');

                }
            )
            return response
        },
        async toggleFeatureRouteStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.featureUpdate,
                async () => {
                    response= await axios.get(V1API.feature_manage_route_toggle+'/'+payload.id).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchFeature');

                }
            )
            return response
        },

        async deleteFeature({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.featureDelete,
                async () => {
                    response = await axios.delete(V1API.feature_manage_delete+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchFeature');
                }
            )

            return response
        },

        async fetchFeature ({ commit }) {
            commit('FEATURE_LOADING', true);
            commit('FEATURE_ERROR', false);
            try{

                let result = await axios.get(V1API.feature_manage_get);
                let response=decode(result.data)
                console.log(response.data)

                commit('SET_FEATURE', response.data);

            }catch(error) {

                commit('FEATURE_ERROR', true);

            }
            commit('FEATURE_LOADING', false);
        },
        async addFeature({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.feature_manage_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchFeature')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
