import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {version} from "uuid";

const keyList = {
    VersionUpdate : 'VERSION_UPDATE',
    VersionDelete : 'VERSION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        version: {},
        versionLoading: false,
        versionError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_VERSION (state, VERSION) {
            state.version = VERSION;
        },

        VERSION_LOADING (state, loading) {
            state.versionLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.versionLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateVersion({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.versionUpdate,
                async () => {
                    let payloadData={
                        buildNumber: payload.buildNumber,
                        version: payload.version,
                        majorUpdate: payload.majorUpdate,
                        update: payload.update,
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.version_edit,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchVersion');

                }
            )
            console.log(response)
            return response
        },




        async fetchVersion ({ commit },payload) {
            commit('VERSION_LOADING', true);
            commit('VERSION_ERROR', false);
            try{

                let result = await axios.get(V1API.version_get);
                let response=decode(result.data)
                commit('SET_VERSION', response.data);
                return response.data
            }catch(error) {

                commit('VERSION_ERROR', true);

            }
            commit('VERSION_LOADING', false);
        },


    },
    getters: {}
}
